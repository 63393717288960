import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/banners/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/banners/listpage', { params });
  },

  // // 根据ID获取
  // getById: (id) => {
  //   return axios.get(`/banners/${id}`);
  // },

  // 新增
  create: (data) => {
    return axios.post('/banners', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/banners', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/banners/${id}`);
  },

  // 新增
  uploadImage: (multipartFiles) => {
    return axios.post('/files/images/upload', { multipartFiles });
  },
};
