<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <!-- <el-form :inline="true">
            <el-form-item label="关键字 ：">
              <el-input size="small" placeholder="请输入关键字" v-model="filterData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form> -->
        </div>
        <div class="handle">
          <el-button size="small" type="primary" @click="handleCreate('form')">添加</el-button>
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="80"> </el-table-column>
          <el-table-column prop="bannerUrl" label="banner图" min-width="100">
            <template slot-scope="scope">
              <img @click='zoom(scope.row)' style="width:160px;height:100px;" :src="scope.row.bannerUrl" />
            </template>
          </el-table-column>
          <el-table-column prop="carId" label="竞拍ID" min-width="100"> </el-table-column>
          <el-table-column prop="sort" label="排序" min-width="100"> </el-table-column>
          <el-table-column prop="createtime" label="创建时间" min-width="100"> </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button @click.native.stop="handleEdit(scope.row, 'form')" type="text">编辑</el-button>
              |
              <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + scope.row.salesTitle + '】记录吗？'" @confirm="handleDeleteConfirm(scope.row)" placement="bottom-end">
                <el-button type="text" slot="reference" @click.native.stop="" style="color:red">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 添加、编辑，模态框组件 -->
      <el-dialog :title="dialogTitle" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
        <el-form :model="form" ref="form" label-width="160px">
          <el-form-item label="上传图片" prop="salesNotice">
            <el-upload class="avatar-uploader" action="/api/files/images/upload" name="multipartFiles" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="form.bannerUrl" :src="form.bannerUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="竞拍ID" prop="carId">
            <el-input v-model="form.carId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle">
          <el-button type="primary" size="small" @click="handleEdit(drawerDetailObj, 'form')">编辑</el-button>
          <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + drawerDetailObj.salesTitle + '】记录吗？'" @confirm="handleDeleteConfirm(drawerDetailObj)" placement="bottom-end">
            <el-button type="danger" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="图片" :liValue="drawerDetailObj.bannerUrl"></DrawerDetail>
              <DrawerDetail liLabel="关联车辆" :liValue="drawerDetailObj.carId"></DrawerDetail>
              <DrawerDetail liLabel="排序" :liValue="drawerDetailObj.sort"></DrawerDetail>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/banner';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
  },

  data() {
    return {
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        keyword: '',
      },

      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      //模态框
      isEdit: false,
      idEdit: '',
      dialogVisible: false,
      dialogTitle: '添加',

      drawerDetailVisible: false,
      drawerDetailObj: {},

      form: {
        bannerUrl: '',
        carId: '',
        sort: '',
      },
    };
  },

  mounted() {
    this.getTableList();
  },

  methods: {
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        console.log(res);
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }
      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }

      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //添加按钮
    handleCreate(formName) {
      this.isEdit = false;
      this.dialogTitle = '添加';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = '';
        }
      });
    },

    //列表里操作列编辑按钮
    handleEdit(row, formName) {
      this.isEdit = true;
      this.idEdit = row.id;
      this.dialogTitle = '编辑';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = row[key];
        }
      });
    },

    //添加、编辑模态框的保存提交按钮
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.form);

          if (!this.isEdit) {
            Request.create(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('添加成功');
                this.searchParams.page = 1;
                this.getTableList();
              } else {
                this.msgError('添加失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          } else {
            data.id = this.idEdit;
            Request.update(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('编辑成功');
                this.getTableList();

                if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
              } else {
                this.msgError('编辑失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },

    //列表里操作列删除按钮弹出提示框的 确定 按钮
    handleDeleteConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.remove(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('删除成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },

    handleAvatarSuccess(res, file) {
      console.log(res);
      this.form.bannerUrl = res.data;
      //this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      }
      return isJPG || isPNG;
    },

    zoom(row){
      window.open(row.bannerUrl)
    }
  },
};
</script>

<style lang="scss" scope>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
